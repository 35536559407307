.footer_container {
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.sep_container > h4 {
    color: ghostwhite;
    display: block;
}

.footer_sites_container {
    display: flex;
  justify-content: center;
  margin: 15px 0px;
}

.icon_logos {
    color: ghostwhite;
    font-size: 38px;
    margin: 0px 20px 10px 20px;
  }

@media screen and (max-width: 1024px) {
  .footer_sites_container {
    /* margin: 200px; */
  }
}
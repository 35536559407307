.navbar {
  height: 70px;
  width: 100vw;
  background-color: rgb(20, 20, 20);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Noto Sans TC", sans-serif;
  position: fixed;
  top: 0;
  z-index: 100;
}

.mylinks {
  color: ghostwhite;
  margin-right: 6rem;
  width: 24rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.navlogo {
  margin-left: 3rem;
  color: ghostwhite;
  font-size: 1.5rem;
}

.navlogo:hover {
  color: rgb(228, 92, 92);
  cursor: pointer;
}

.navlinks {
  margin: 0rem 1rem;
}

.navlinks:hover {
  color: rgb(228, 92, 92);
  cursor: pointer;
}

/* .resumealink {
  text-decoration: none;
}

.resumelink {
  text-decoration: none;
  color: ghostwhite;
  font-size: 1.5rem;
}

.resumelink:hover {
  color: rgb(228, 92, 92);
  cursor: pointer;
} */

@media screen and (max-width: 1250px) {
  .mylinks {
    margin-right: .5rem;
  }
}

@media screen and (max-width: 1024px) {
  .navlogo:hover {
    color: ghostwhite;
  }
}

@media screen and (max-width: 768px) {
  .navlogo{
    margin-left: .7rem;
  }
  .navlogo:hover {
    color: ghostwhite;
  }
}

@media screen and (max-width: 420px) {
  .navlogo {
    font-size: 16px;
  }
  .mylinks {
    display: none;
  }
  .navlogo:hover {
    color: ghostwhite;
  }
}
.container {
    background-image: url('https://images.pexels.com/photos/326240/pexels-photo-326240.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500');
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.info_container {
    background-color: rgba(44, 38, 38, 0.7);
    color: rgb(241, 241, 253);
    height: 500px;
    width: 500px;
    border-radius: 5px;
    margin: 50px 50px;
    padding: 20px;
  }

.pic_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    height: 500px;
    width: 500px;
    margin: 50px 20px;
    padding: 20px;
}

.info_container > h1 {
    margin-top: 40px;
    font-size: 42px;
}

.info_container > h4 {
    margin: 0px 20px;
    font-size: 20px;
    text-align: start;
    line-height: 28px;
}

.pic_container > img {
    height: 500px;
    width: auto;
    border-radius: 50%;
    border: 9px solid rgb(90, 83, 83);
    padding: 7px;
}

@media screen and (max-width: 1250px) {
    .pic_container {
        height: 400px;
        width: 400px;
    }
    .info_container {
        height: 400px;
        width: 400px;
    }
    .pic_container > img {
        height: 400px;
        width: auto;
    }
    .info_container > h1 {
        font-size: 36px;
        margin-top: 10px;
    }
    .info_container > h4 {
        font-size: 16px;
    }
}

@media screen and (max-width: 1019px) {
    .pic_container {
        height: 300px;
        width: 550px;
        margin-top: 40px;
    }
    .info_container {
        height: 300px;
        width: 550px;
        margin-top: 0px;
    }
    .pic_container > img {
        height: 300px;
        width: auto;
    }
    .info_container > h1 {
        font-size: 36px;
        margin: 10px 0px;
    }
    .info_container > h4 {
        font-size: 16px;
    }
}

@media screen and (max-width: 420px) {
    .pic_container {
        height: 200px;
        width: 200px;
    }
    .info_container {
        height: 300px;
        width: 350px;
        margin-top: -2rem;
    }
    .pic_container > img {
        height: 200px;
        width: auto;
    }
    .info_container > h1 {
        font-size: 24px;
        margin-top: -5px;
    }
    .info_container > h4 {
        font-size: 13px;
        line-height: 18px;
        margin: 0px 0px;
    }
}

.container {
  background-image: url("https://images.pexels.com/photos/1227511/pexels-photo-1227511.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* filter: blur(1px) */
}

.header_container > h1 {
  font-size: 6.2rem;
  text-shadow: 2px 2px 3px rgb(133, 14, 40);
  margin-top: 20px;
}

.rotating_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 835px;
}

.rotating_container > h2 {
  font-size: 3.8rem;
  text-shadow: 2px 2px 3px rgb(133, 14, 40);
  text-align: left;
}

.rotating_names {
  font-size: 3.8rem;
  color: ghostwhite;
  text-shadow: 2px 2px 3px rgb(133, 14, 40);
  margin-left: 8px;
}

.header_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 15rem;
  height: 3.5rem;
  font-size: 2rem;
  background-color: rgb(19, 19, 19);
  border-radius: 5px;
  color: ghostwhite;
  box-shadow: 0px 0px 10px .5px ghostwhite;
}
.header_button h3{
  font-size: 2rem;
}

.header_container a {
  text-decoration: none;
}

.header_button:hover {
  background-color: ghostwhite;
  color: rgb(19, 19, 19);
  cursor: pointer;
  box-shadow: 0px 0px 10px .5px rgb(0, 0, 0);
}

.social_sites_container {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.icon_logos {
  color: black;
  font-size: 72px;
  margin: 0px 20px 40px 20px;
  height: 50px;
}

@media screen and (max-width: 1024px) {
  .header_button:hover {
    background-color: black;
    color: ghostwhite;
    box-shadow: 0px 0px 10px 1px rgb(255, 255, 255);
  }
}

@media screen and (max-width: 880px) {
  .rotating_container > h2 {
    font-size: 2.7rem;
  }
  .rotating_names {
    font-size: 2.7rem;
  }
  .rotating_container {
    width: 610px;
  }
  .header_container > h1 {
    font-size: 5.5rem;
  }
  .header_button:hover {
    background-color: black;
    color: ghostwhite;
    box-shadow: 0px 0px 10px 1px rgb(255, 255, 255);
  }
}

@media screen and (max-width: 420px) {
  .header_container > h1 {
    font-size: 3.3rem;
  }
  .rotating_container {
    width: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 141px;
  }
  .rotating_container > h2 {
    font-size: 2.2rem;
    margin-top: -50px;
    position: absolute;
  }
  .rotating_names {
    font-size: 2.2rem;
    position: absolute;
    margin: 50px 0px 20px 0px;
  }
  .icon_logos {
    font-size: 60px;
  }
  .header_button {
    font-size: 1.5rem;
  }
  .header_button:hover {
    background-color: black;
    color: ghostwhite;
    box-shadow: 0px 0px 10px 1px rgb(255, 255, 255);
  }
}
.project_section {
  background-image: url("https://images.pexels.com/photos/235985/pexels-photo-235985.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  padding: 0px 0px 75px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.background_pic {
  background-image: url("https://images.pexels.com/photos/1569012/pexels-photo-1569012.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects_title_div {
  display: flex;
  justify-content: center;
  box-shadow: 4px 4px 5px black;
  width: 25rem;
  height: 5rem;
  background-color: rgb(255, 255, 255, 0.7);
}

.project_text {
  font-size: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans TC", sans-serif;
}

.projects_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  padding: 1.5rem 0rem;
}

.item {
  position: relative;
  height: 23rem;
  overflow-y: hidden;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin: 20px 30px;
}

.item__overlay > h3 {
  margin: 0;
  display: block;
  background-color: rgb(2, 10, 9);
  color: ghostwhite;
  padding: 1.1rem;
  text-align: left;
}

.project__image {
  width: 500px;
  height: 375px;
  display: block;
  object-fit: cover;
  background-size: cover;
}

.project__image > img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.item__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: inherit;
  top: 0;
  transition: transform 300ms;
  background-image: linear-gradient(rgb(0, 0, 0), rgb(20, 46, 97));
  color: ghostwhite;
  transform: translate3d(0, calc(100% - 3.5rem), 0);
}

.item__overlay:hover {
  transform: translate3d(0, 0, 0);
}

.item__body {
  flex-grow: 1;
  padding: 1rem;
}

.item__body > p {
  margin: 0px 13px;
  font-size: 16px;
  line-height: 20px;
}

.item__body > h2 {
  margin-top: -5px;
}

a > button {
  font-size: 20px;
  margin: 30px 10px 0px 10px;
  padding: 3px 10px;
  font-weight: 600;
  cursor: pointer;
}

a > button:hover {
  color: ghostwhite;
  background-color: rgb(54, 198, 211);
  text-shadow: 1px 1px 1px black;
}